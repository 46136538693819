import * as Types from '../../graphql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type ActiveBan = {
  __typename: 'ActiveBan';
  endsAt: Scalars['String']['output'];
  reason: Scalars['String']['output'];
};

export type Announcement = {
  __typename: 'Announcement';
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  image?: Maybe<Scalars['String']['output']>;
  message: Scalars['String']['output'];
  reactions?: Maybe<Array<AnnouncementReaction>>;
  title?: Maybe<Scalars['String']['output']>;
  type: Scalars['Int']['output'];
};

export type AnnouncementReaction = {
  __typename: 'AnnouncementReaction';
  count: Scalars['Int']['output'];
  reaction: AnnouncementReactionType;
};

export enum AnnouncementReactionType {
  Clap = 'clap',
  Heart = 'heart',
  ThumbsDown = 'thumbsDown',
  ThumbsUp = 'thumbsUp'
}

export enum CreateUserError {
  InvalidToken = 'invalidToken',
  None = 'none',
  UsernameTaken = 'usernameTaken'
}

export type CreateUserResponse = {
  __typename: 'CreateUserResponse';
  token?: Maybe<Scalars['String']['output']>;
  valid: CreateUserError;
};

export type Dog = {
  __typename: 'Dog';
  bones: Scalars['Int']['output'];
  color: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  inventory?: Maybe<Array<Item>>;
  isDead: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  role?: Maybe<Scalars['String']['output']>;
  shadowColor: Scalars['Int']['output'];
  user?: Maybe<User>;
  username?: Maybe<Scalars['String']['output']>;
  wear: Wear;
};

export type Error = {
  __typename: 'Error';
  message: Scalars['String']['output'];
};

export type Highscore = {
  __typename: 'Highscore';
  dogname: Scalars['String']['output'];
  isMe: Scalars['Boolean']['output'];
  position: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
};

export enum HighscoreInterval {
  AllTime = 'AllTime',
  Daily = 'Daily',
  Weekly = 'Weekly'
}

export type Item = {
  __typename: 'Item';
  count?: Maybe<Scalars['Int']['output']>;
  description: Scalars['String']['output'];
  gfx: Scalars['String']['output'];
  gfxList?: Maybe<Array<Scalars['Int']['output']>>;
  id: Scalars['Int']['output'];
  itemRestockSettings?: Maybe<Array<ItemRestockSetting>>;
  lastSold?: Maybe<ShopItem>;
  name: Scalars['String']['output'];
  nameNo: Scalars['String']['output'];
  owners?: Maybe<Array<Dog>>;
  shopHistory?: Maybe<Array<ShopItem>>;
  usageType?: Maybe<UsageType>;
  usageTypes?: Maybe<Array<Maybe<UsageType>>>;
};

export enum ItemFilter {
  Accepted = 'Accepted',
  All = 'All',
  Draft = 'Draft',
  MyUnaccepted = 'MyUnaccepted',
  Unaccepted = 'Unaccepted'
}

export type ItemRestockSetting = {
  __typename: 'ItemRestockSetting';
  id: Scalars['Int']['output'];
  item?: Maybe<Item>;
  itemId: Scalars['Int']['output'];
  priceFrom: Scalars['Int']['output'];
  priceTo: Scalars['Int']['output'];
  restockShop?: Maybe<RestockShop>;
};

export type ItemTracking = {
  __typename: 'ItemTracking';
  action?: Maybe<Scalars['Int']['output']>;
  date: Scalars['String']['output'];
  from?: Maybe<Dog>;
  id: Scalars['Int']['output'];
  to?: Maybe<Dog>;
};

export enum Lang {
  Dk = 'dk',
  En = 'en',
  No = 'no',
  Se = 'se'
}

export type LoginResponse = {
  __typename: 'LoginResponse';
  newUser?: Maybe<Scalars['Boolean']['output']>;
  token: Scalars['String']['output'];
};

export enum MiniGame {
  CatsFree = 'CatsFree',
  JumpingDog = 'JumpingDog',
  KnogleKnas = 'KnogleKnas'
}

export type Mutation = {
  __typename: 'Mutation';
  addBones?: Maybe<Scalars['Boolean']['output']>;
  addRestockShopSetting: Scalars['Int']['output'];
  addShopItems: Scalars['Boolean']['output'];
  clearUnsoldItems: Scalars['Boolean']['output'];
  createUser: CreateUserResponse;
  deleteRestockShopSetting: Scalars['Boolean']['output'];
  googleLogin?: Maybe<LoginResponse>;
  newDog?: Maybe<Error>;
  setLanguage: Scalars['Boolean']['output'];
  setUserRole: Scalars['Boolean']['output'];
  setUsername: Response;
  updateRestockShopSetting: Scalars['Boolean']['output'];
};


export type MutationAddBonesArgs = {
  amount: Scalars['Int']['input'];
  dogId: Scalars['Int']['input'];
};


export type MutationAddRestockShopSettingArgs = {
  itemId: Scalars['Int']['input'];
  priceFrom: Scalars['Int']['input'];
  priceTo: Scalars['Int']['input'];
  restockShopId: Scalars['Int']['input'];
};


export type MutationAddShopItemsArgs = {
  items: Array<RestockItem>;
  shopId: Scalars['Int']['input'];
};


export type MutationClearUnsoldItemsArgs = {
  shopId: Scalars['Int']['input'];
};


export type MutationCreateUserArgs = {
  language: Lang;
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationDeleteRestockShopSettingArgs = {
  restockShopSettingId: Scalars['Int']['input'];
};


export type MutationGoogleLoginArgs = {
  language: Lang;
  token: Scalars['String']['input'];
};


export type MutationNewDogArgs = {
  dogId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSetLanguageArgs = {
  language?: InputMaybe<Lang>;
};


export type MutationSetUserRoleArgs = {
  roleId?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['Int']['input'];
};


export type MutationSetUsernameArgs = {
  username: Scalars['String']['input'];
};


export type MutationUpdateRestockShopSettingArgs = {
  priceFrom: Scalars['Int']['input'];
  priceTo: Scalars['Int']['input'];
  restockShopSettingId: Scalars['Int']['input'];
};

export type NewDogColors = {
  __typename: 'NewDogColors';
  color: Scalars['Int']['output'];
  shadowColor: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export enum PatreonTier {
  Bronze = 'Bronze',
  Diamond = 'Diamond',
  Gold = 'Gold',
  Inactive = 'Inactive',
  Silver = 'Silver'
}

export enum Permission {
  AcceptItem = 'ACCEPT_ITEM',
  AddAcceptableItem = 'ADD_ACCEPTABLE_ITEM',
  AllItems = 'ALL_ITEMS',
  ChatLog = 'CHAT_LOG',
  ItemTracking = 'ITEM_TRACKING',
  Restock = 'RESTOCK',
  RolePermissions = 'ROLE_PERMISSIONS',
  Shops = 'SHOPS',
  Users = 'USERS'
}

export type Query = {
  __typename: 'Query';
  discord: Scalars['String']['output'];
  dog?: Maybe<Dog>;
  getAnnouncement?: Maybe<Announcement>;
  getDog?: Maybe<Dog>;
  getDogs: Array<Dog>;
  getItemLastTracking?: Maybe<ItemTracking>;
  getItemNextTracking?: Maybe<ItemTracking>;
  getRole?: Maybe<Role>;
  getRoles?: Maybe<Array<Role>>;
  getSetting?: Maybe<Scalars['String']['output']>;
  getUsers: Array<User>;
  highscores: Array<Highscore>;
  item?: Maybe<Item>;
  itemCount: Array<Item>;
  items?: Maybe<Array<Item>>;
  latestAnnouncements: Array<Announcement>;
  myUser?: Maybe<User>;
  newDog: NewDogColors;
  ownedItems: Array<Item>;
  patreonSubscription?: Maybe<UserPatreonSubscription>;
  randomDog?: Maybe<Dog>;
  restockShop: RestockShop;
  restockShopSetting: Array<ItemRestockSetting>;
  restockShops: Array<RestockShop>;
  shop?: Maybe<Shop>;
  shops?: Maybe<Array<Shop>>;
  team: Array<Dog>;
  userReports?: Maybe<Array<UserReport>>;
};


export type QueryDogArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetAnnouncementArgs = {
  id: Scalars['Int']['input'];
};


export type QueryGetDogArgs = {
  dogId: Scalars['Int']['input'];
};


export type QueryGetItemLastTrackingArgs = {
  dogId: Scalars['Int']['input'];
  itemId: Scalars['Int']['input'];
};


export type QueryGetItemNextTrackingArgs = {
  itemId: Scalars['Int']['input'];
  lastId: Scalars['Int']['input'];
};


export type QueryGetRoleArgs = {
  roleId: Scalars['Int']['input'];
};


export type QueryGetSettingArgs = {
  type: SettingType;
};


export type QueryHighscoresArgs = {
  game: MiniGame;
  interval: HighscoreInterval;
};


export type QueryItemArgs = {
  id: Scalars['Int']['input'];
};


export type QueryItemCountArgs = {
  onlyInUse: Scalars['Boolean']['input'];
};


export type QueryItemsArgs = {
  filter: ItemFilter;
};


export type QueryRestockShopArgs = {
  id: Scalars['Int']['input'];
};


export type QueryRestockShopSettingArgs = {
  restockShopId: Scalars['Int']['input'];
};


export type QueryShopArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTeamArgs = {
  types: Array<Scalars['String']['input']>;
};


export type QueryUserReportsArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};

export type Response = {
  __typename: 'Response';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum RestockInterval {
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type RestockItem = {
  amount: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  from: Scalars['String']['input'];
  itemId: Scalars['Int']['input'];
  price: Scalars['Int']['input'];
  to: Scalars['String']['input'];
};

export type RestockShop = {
  __typename: 'RestockShop';
  catchAll: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  interval: RestockInterval;
  itemSettings?: Maybe<Array<Maybe<ItemRestockSetting>>>;
  shop?: Maybe<Shop>;
  shopId: Scalars['Int']['output'];
  strategy: RestockStrategy;
};

export enum RestockStrategy {
  Fifo = 'FIFO',
  Random = 'RANDOM'
}

export type Role = {
  __typename: 'Role';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<RolePermissions>>;
  users?: Maybe<Array<User>>;
};

export type RolePermissions = {
  __typename: 'RolePermissions';
  permission: Permission;
};

export enum SettingType {
  DisableClient = 'DISABLE_CLIENT'
}

export type Shop = {
  __typename: 'Shop';
  id: Scalars['Int']['output'];
  items?: Maybe<Array<ShopItem>>;
  name: Scalars['String']['output'];
  restockShop?: Maybe<RestockShop>;
};


export type ShopItemsArgs = {
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ShopItem = {
  __typename: 'ShopItem';
  fromDate: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  item?: Maybe<Item>;
  karmaCost?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  shop?: Maybe<Shop>;
  source?: Maybe<Scalars['String']['output']>;
  toDate: Scalars['String']['output'];
  tradeItem?: Maybe<Item>;
  tradeItemAmount?: Maybe<Scalars['Int']['output']>;
};

export enum UsageType {
  Costume = 'Costume',
  Dildo = 'Dildo',
  Explosive = 'Explosive',
  FartExplosive = 'FartExplosive',
  FriendlyExplosive = 'FriendlyExplosive',
  Hat = 'Hat',
  Mouth = 'Mouth',
  None = 'None',
  ProfileObject = 'ProfileObject',
  Pushable = 'Pushable',
  Spray = 'Spray',
  Unequippable = 'Unequippable',
  UnlimitedSpray = 'UnlimitedSpray'
}

export type User = {
  __typename: 'User';
  activeBan?: Maybe<ActiveBan>;
  admin: Scalars['Int']['output'];
  dogs?: Maybe<Array<Dog>>;
  id: Scalars['Int']['output'];
  maxDogs: Scalars['Int']['output'];
  moderator: Scalars['Int']['output'];
  role?: Maybe<Role>;
  username: Scalars['String']['output'];
};

export type UserPatreonSubscription = {
  __typename: 'UserPatreonSubscription';
  isActive: Scalars['Boolean']['output'];
  tier: PatreonTier;
};

export type UserReport = {
  __typename: 'UserReport';
  date: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  offence: Scalars['Int']['output'];
  reported?: Maybe<Dog>;
  reporter?: Maybe<Dog>;
};

export type Wear = {
  __typename: 'Wear';
  back: Scalars['Int']['output'];
  hat: Scalars['Int']['output'];
  mouth: Scalars['Int']['output'];
};

export type GetLatestAnnouncementsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetLatestAnnouncementsQuery = { __typename: 'Query', latestAnnouncements: Array<{ __typename: 'Announcement', id: number, title?: string | null, message: string, date: string }> };


export const GetLatestAnnouncementsDocument = gql`
    query GetLatestAnnouncements {
  latestAnnouncements {
    id
    title
    message
    date
  }
}
    `;

/**
 * __useGetLatestAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetLatestAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>(GetLatestAnnouncementsDocument, options);
      }
export function useGetLatestAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>(GetLatestAnnouncementsDocument, options);
        }
export function useGetLatestAnnouncementsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>(GetLatestAnnouncementsDocument, options);
        }
export type GetLatestAnnouncementsQueryHookResult = ReturnType<typeof useGetLatestAnnouncementsQuery>;
export type GetLatestAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetLatestAnnouncementsLazyQuery>;
export type GetLatestAnnouncementsSuspenseQueryHookResult = ReturnType<typeof useGetLatestAnnouncementsSuspenseQuery>;
export type GetLatestAnnouncementsQueryResult = Apollo.QueryResult<GetLatestAnnouncementsQuery, GetLatestAnnouncementsQueryVariables>;