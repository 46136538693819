import { InformationBox } from '../components';
import { FunctionComponent } from 'react';
import styles from './Otherpages.module.scss';

export const EULAPage: FunctionComponent = () => {
	return (
		<div className={styles.page}>
			<div className={styles.container}>
				<InformationBox
					title={'Removal of your account'}
					color="#ffc600"
					icon="/images/paragraf.png"
					sections={[
						{
							content: (
								<p>
									To get deleted from Hundeparken send a email to <u>mail@hundeparken.net</u> from the account of which
									you want to get deleted.
								</p>
							),
						},
					]}
				/>
			</div>
		</div>
	);
};
